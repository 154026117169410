import React from 'react';

import { HashRouter, Route, Switch } from 'react-router-dom';

import App from './App';

const Router = () => (
    <HashRouter>
        <Switch>
            <Route path="/:id" render={(props: any) => <App {...props} />}/>
            <Route component={App}/>
        </Switch>
    </HashRouter>
);

export default Router;
