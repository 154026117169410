import { CSSProperties } from 'react';

import { createStyles, makeStyles, Theme } from '@material-ui/core';

import clock from './clock.svg';

export const toolbarHeight = 64;

export const marginCard = 15;

export const bold: CSSProperties = {
    fontWeight: 'bold',
};

export const transparent: CSSProperties = {
    background: 'transparent',
};

export const capitalize: CSSProperties = {
    textTransform: 'capitalize',
};

export const fullHeight: CSSProperties = {
    height: `calc(100vh - ${toolbarHeight}px)`,
};

export const maxWidth: CSSProperties = {
    width: '100%',
    maxWidth: 520,
};

export const root: CSSProperties = {
    margin: `${marginCard}px auto 0 auto`,
    ...maxWidth,
    ...fullHeight,
    ...transparent,
};

export const fontLarge = (theme: Theme): CSSProperties => ({
    fontSize: 25,
    [`${theme.breakpoints.down('xs')}`]: {
        fontSize: '5vw',
    },
});

export const fontButton = (theme: Theme): CSSProperties => ({
    fontSize: 20,
    [`${theme.breakpoints.down('xs')}`]: {
        fontSize: '4vw',
    },
});

export const fontMedium = (theme: Theme): CSSProperties => ({
    fontSize: 15,
    [`${theme.breakpoints.down('xs')}`]: {
        fontSize: '3vw',
    },
});

export const fontSmall = (theme: Theme): CSSProperties => ({
    fontSize: 11,
    [`${theme.breakpoints.down('xs')}`]: {
        fontSize: '2.5vw',
    },
});

export const primary = (theme: Theme): CSSProperties => ({
    color: theme.palette.primary.main,
});

export const title = (theme: Theme): CSSProperties => ({
    margin: '3vh 0',
    lineHeight: '1.217',
    textAlign: 'center',
    ...primary(theme),
    ...fontLarge(theme),
});

const useStyles = makeStyles(
    (theme: Theme) => createStyles({
        paper: {
            margin: 'auto',
            minHeight: '100vh',
            backgroundImage: `url('${clock}')`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 400,
            backgroundPosition: 'top -100px left -100px',
            ...maxWidth,
        },
        logo: {
            borderRadius: 0,
            width: theme.spacing(4),
            height: theme.spacing(4),
        },
        backButton: {
            marginLeft: -14,
        },
        space: {
            flexGrow: 1,
        },
        filledInfo: {
            background: theme.palette.primary.main,
        },
        toolbar: {
            minHeight: `${toolbarHeight}px !important`,
        },
        loader: {
            position: 'absolute',
            top: 'calc(50vh - 40px)',
            left: 'calc(50vw - 40px)',
        },
        swipeableViews: {
            height: `calc(100vh - ${toolbarHeight}px)`,
        },
        notfound: {
            position: 'absolute',
            top: '20vh',
            fontWeight: 'lighter',
            textAlign: 'center',
            ...fontSmall(theme),
            ...maxWidth,
        },
    }),
);

export default useStyles;
