import { createStyles, makeStyles, Theme } from '@material-ui/core';

import { bold, fontLarge, fontMedium, capitalize, primary, transparent } from '../../../style';

const useStyles = makeStyles(
    (theme: Theme) => createStyles({
        transparent: {
            ...transparent,
        },
        primary: {
            ...primary(theme),
        },
        capitalize: {
            ...capitalize,
        },
        bold: {
            ...bold,
        },
        fontLarge: {
            ...fontLarge(theme),
        },
        list: {
            padding: '8px 30px',
        },
        daylittleResume: {
            fontWeight: 'bold',
            textAlign: 'center',
            fontSize: 30,
            [`${theme.breakpoints.down('xs')}`]: {
                fontSize: '5vw',
            },
        },
        littleResume: {
            textAlign: 'center',
            fontSize: 20,
            [`${theme.breakpoints.down('xs')}`]: {
                fontSize: '4vw',
            },
        },
        cardContent: {
            background: 'transparent',
            padding: `${theme.spacing(2)}px !important`,
            paddingBottom: '0 !important',
            overflowX: 'auto',
            boxShadow: '0 16px 12px -16px rgba(0,0,0,0.2) inset',
        },
        slide: {
            margin: '0 10px',
            width: 'calc(100% - 20px) !important',
            transition: 'width .2s ease',
        },
        halfSlide: {
            width: 'calc(50% - 20px) !important',
        },
        buttonHours: {
            width: '100%',
            margin: `${theme.spacing(2)}px 0`,
            borderWidth: '2px !important',
            borderColor: theme.palette.primary.main,
            height: 60,
        },
        buttonConfirmMasked: {
            width: '100%',
            margin: `${theme.spacing(2)}px 0`,
            height: 60,
        },
        labelButton: {
            fontSize: 20,
            [`${theme.breakpoints.down('xs')}`]: {
                fontSize: '4vw',
            },
        },
        containerMinutes: {
            marginBottom: 30,
        },
        itemMinutes: {
            textAlign: 'center',
        },
        buttonMinutes: {
            width: '100%',
            minWidth: 0,
            maxWidth: 80,
        },
        fontButtonMinutes: {
            ...fontMedium(theme),
        },
    }),
);

export default useStyles;
