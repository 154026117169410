// eslint-disable-next-line no-process-env
export const basePath = `${process.env.REACT_APP_API_ENDPOINT}/api/appointment/`;

export const getLeadUrl = (
    { tinyId } :
    {tinyId: string | undefined},
) => `${basePath}lead?tinyId=${tinyId}`;

export const getConfigUrl = (
    { tinyId } :
    {tinyId: string | undefined},
) => `${basePath}config?tinyId=${tinyId}`;

export const getLeadsUrl = (
    { date, formId }:
    {date: string | undefined, formId: string | undefined},
) => (
    `${basePath}leads?date=${date}&formId=${formId}`
);

export const updateLeadUrl = `${basePath}updateLead`;
