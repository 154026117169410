/* eslint-disable camelcase */
import React from 'react';

import { Avatar, Button, List, ListItem, ListItemAvatar,
    ListItemText } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import clsx from 'clsx';
import moment, { Moment } from 'moment';

import useStyles from './theme/style';
import 'moment/locale/fr';

moment.locale('fr');

const HomeTab = (
    {
        message,
        initDate,
        handleUpdate,
    }:{
        message: string | undefined,
        initDate: Moment,
        handleUpdate: any
    },
) => {
    const classes = useStyles();

    const sHour = moment(initDate)
        .format('HH[h]mm');

    const isOutDated = moment(initDate).isBefore(moment());

    return (
        <>
            <List className={classes.list}>
                <ListItem>
                    {
                        !isOutDated &&
                        <ListItemAvatar>
                            <Avatar
                                className={clsx(classes.primary, classes.transparent)}
                            >
                                <AccessTimeIcon />
                            </Avatar>
                        </ListItemAvatar>
                    }
                    <ListItemText
                        classes={
                            {
                                primary: clsx(classes.fontLarge, {
                                    [classes.primary]: !isOutDated,
                                    [classes.center]: isOutDated,
                                }),
                                secondary: clsx({
                                    [classes.center]: isOutDated,
                                }),
                            }
                        }
                        primary={
                            isOutDated
                                ? 'Oups ! On a raté notre rendez-vous ?'
                                : `Votre rendez-vous${!message ? '' : ` ${message}`}, est enregistré pour le`
                        }
                        secondary={
                            <>
                                {
                                    !isOutDated ?
                                        <>
                                            <Typography
                                                component="span"
                                                className={clsx(classes.fontLarge, classes.capitalize)}
                                            >
                                                {`${initDate.format('dddd DD MMMM')}`}
                                            </Typography>
                                            <Typography
                                                component="span"
                                                className={classes.fontLarge}
                                            >
                                                {' à '}
                                            </Typography>
                                            <Typography
                                                component="span"
                                                className={clsx(classes.fontLarge, classes.bold)}
                                            >
                                                {`${sHour}`}
                                            </Typography>
                                        </>
                                        :
                                        <>
                                            <Typography
                                                component="span"
                                                className={classes.fontMedium}
                                            >
                                                {'Cliquez sur MODIFIER pour le reprogrammer'}
                                            </Typography>
                                        </>
                                }
                            </>
                        }
                    />
                </ListItem>
            </List>
            <Button
                className={classes.buttonConfirm}
                variant="contained"
                disableElevation
                color="primary"
                onClick={handleUpdate}
                classes={
                    {
                        label: classes.fontButton,
                    }
                }
            >
                Modifier
            </Button>
        </>
    );
};

export default HomeTab;
