import { capitalize, createStyles, makeStyles, Theme } from '@material-ui/core';

import { bold, fontLarge, fontMedium, primary, transparent } from '../../../style';

const useStyles = makeStyles(
    (theme: Theme) => createStyles({
        transparent: {
            ...transparent,
        },
        primary: {
            ...primary(theme),
        },
        capitalize: {
            ...capitalize,
        },
        bold: {
            ...bold,
        },
        fontLarge: {
            ...fontLarge(theme),
        },
        fontMedium: {
            ...fontMedium(theme),
        },
        list: {
            marginTop: 50,
            padding: '8px 30px',
        },
        spacingxs: {
            padding: 48,
            width: '100% !important',
        },
        datetinyday: {
            fontSize: 60,
            marginTop: -5,
            marginBottom: -5,
            fontWeight: 'bold',
            [`${theme.breakpoints.down('xs')}`]: {
                fontSize: '10vw',
            },
        },
        buttonMore: {
            width: 240,
            margin: '0px auto 40px auto',
        },
    }),
);

export default useStyles;
