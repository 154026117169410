import { createStyles, makeStyles, Theme } from '@material-ui/core';

import { bold, capitalize, fontButton, fontLarge, primary, transparent } from '../../../style';


const useStyles = makeStyles(
    (theme: Theme) => createStyles({
        transparent: {
            ...transparent,
        },
        primary: {
            ...primary(theme),
        },
        capitalize: {
            ...capitalize,
        },
        bold: {
            ...bold,
        },
        fontLarge: {
            ...fontLarge(theme),
        },
        fontButton: {
            ...fontButton(theme),
        },
        list: {
            width: '100%',
            position: 'absolute',
            top: '15vh',
            '@media (max-height: 650px) and (orientation: landscape)': {
                top: '0vh',
            },
        },
        listItembutton: {
            paddingLeft: theme.spacing(1),
        },
        listItemTextPrimaryMessage: {
            fontSize: 25,
            [`${theme.breakpoints.down('xs')}`]: {
                fontSize: '4.5vw',
            },
        },
        buttonConfirmContainer: {
            margin: theme.spacing(1),
            position: 'absolute',
            bottom: '20vh',
            width: `calc(100% - ${theme.spacing(2)}px)`,
            '@media (max-height: 650px) and (orientation: landscape)': {
                bottom: '0vh',
            },
        },
        buttonConfirm: {
            width: '100%',
            height: 60,
        },
        labelButton: {
            fontSize: 20,
            [`${theme.breakpoints.down('xs')}`]: {
                fontSize: '4vw',
            },
        },
    }),
);

export default useStyles;
