import React from 'react';

import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import ReactDOM from 'react-dom';

import reportWebVitals from './reportWebVitals';
import Router from './router';


import './index.css';


const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#00ACD5',
            contrastText: '#fff',
        },
    },
});

ReactDOM.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <Router />
        </ThemeProvider>
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
