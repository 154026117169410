/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';

import { AppBar, Avatar, CircularProgress, IconButton, Paper, Snackbar, Toolbar, Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import axios, { AxiosResponse } from 'axios';
import _ from 'lodash';
import moment, { Moment } from 'moment';
import SwipeableViews from 'react-swipeable-views';

import { getConfigUrl, getLeadsUrl, getLeadUrl, updateLeadUrl } from './components/api/constant';
import DateTab from './components/date';
import HomeTab from './components/home';
import HoursTab from './components/hours';
import ResumeTab from './components/resume';
import logo from './logo.svg';
import useStyles from './style';

const week: number[] = [0, 1, 2, 3, 4, 5, 6];

export const rangeHour = 60;

const ics = require('ics');

const Alert = (props: AlertProps) => <MuiAlert elevation={6} variant="filled" {...props} />;

export const toCapitalize = (string: string) => string.charAt(0).toUpperCase() + string.slice(1);

const App = (props: any) => {
    const id = props?.match?.params?.id;
    const classes = useStyles();

    const [today, setToday] = useState<Moment>(moment());
    const [message, setMessage] = useState<string | undefined>();
    const [rangeMinute, setRangeMinute] = useState<number>(15);
    const [durationAppointment, setDurationAppointment] = useState<number>(15);
    const [numberOperator, setNumberOperator] = useState<number>(5);

    const [lead, setLead] = useState<any>();
    const [businessDays, setBusinessDays] = useState<any>();
    const [config, setConfig] = useState<any>();
    const [closedWeekdays, setClosedWeekdays] = useState<number[]>();
    const [closedDays, setClosedDays] = useState<Moment[]>([]);
    const [closedHours, setClosedHours] = useState<string[]>();
    const [closedMinutes, setClosedMinutes] = useState<string[]>();

    const [index, setIndex] = useState<number>(0);

    const [initDate, setInitDate] = useState<Moment | undefined>();
    const [date, setDate] = useState<Moment | undefined>();
    const [hour, setHour] = useState<number | undefined>();
    const [minutes, setMinutes] = useState<number>(0);
    const [ical, setIcal] = useState<boolean>(true);
    const [reset, setReset] = useState<boolean>(false);
    const [confirm, setConfirm] = useState<boolean>(false);
    const [snackbar, setSnackbar] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState(Boolean(id));

    const handleCloseSnackbar = (_event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbar(false);
    };

    const handleUpdate = () => {
        setIndex(1);
    };

    const handleDateChange = (updatedDate: Date[]) => {
        const [upDate] = updatedDate;

        setDate(moment(upDate));
        setIndex(2);
    };

    const handleSelectHour = (value: number) => {
        setMinutes(0);
        setHour(hour === value ? undefined : value);
    };

    const handleSelectMinutes = (value: number) => {
        setMinutes(value);
    };

    const handleConfirmHour = () => {
        if (hour && !closedMinutes?.find((f) => f === moment(date)
            .hour(hour)
            .minute(minutes)
            .format('YYYYMMDDHHmm'))
        ) {
            setIndex(3);
        }
    };

    const handleBack = () => {
        setIndex(index - 1);
    };

    const handleReset = () => {
        setReset(true);
    };

    const handleConfirm = (value: Moment) => {
        setIsLoading(true);
        setDate(value);
        axios.post(updateLeadUrl, {
            TinyId: lead.TinyId,
            AppointmentDate: value.format('YYYY-MM-DD HH:mm'),
        })
            .then(() => {
                setConfirm(true);
            })
            .catch((reason: any) => {
                console.error(reason);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const getLead = () => {
        axios.get(getLeadUrl({
            tinyId: id,
        }))
            .then((value: AxiosResponse<any>) => {
                if (value?.data?.AppointmentDate) {
                    setInitDate(moment(value?.data?.AppointmentDate));
                    setLead(value.data);
                }
            })
            .catch((reason: any) => {
                console.error(reason);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const getConfig = () => {
        axios.get(getConfigUrl({
            tinyId: id,
        }))
            .then((value: AxiosResponse<any>) => {
                const openingDays = value?.data?.Operating_hours?.Opening_days;
                const campaign = Array.isArray(value?.data?.Campaigns)
                    ? value?.data?.Campaigns?.find(
                        (f: Partial<{ Form_Id: string }>) => f.Form_Id?.includes(lead.FormId),
                    )
                    : undefined;

                if (campaign) {
                    setMessage(campaign.Greetings);
                    setRangeMinute(campaign.MinuteRange || 15);
                    setDurationAppointment(campaign.AppointmentDuration || 15);
                    setNumberOperator(campaign.MaxAgentsAvailable || 5);
                }

                if (openingDays && Array.isArray(openingDays)) {
                    setConfig(value?.data);
                    setBusinessDays(openingDays);
                    setClosedWeekdays(week.filter(
                        (f) => !openingDays.map((m) => m.Day).includes(f),
                    ));


                    const businessDay = openingDays.find((f: any) => f.Day === today.day());

                    if (businessDay) {
                        const closeTime = moment(businessDay.CloseTime, 'HH:mm');

                        if (closeTime && moment().isAfter(closeTime)) {
                            setToday(moment(today).add(1, 'd'));
                        }
                    }
                }
            })
            .catch((reason: any) => {
                console.error(reason);
            });
    };

    const getLeads = () => {
        const months: string[] = _.uniq([
            moment().format('YYYYMM'),
            moment()
                .add(7, 'd')
                .format('YYYYMM'),
        ]);

        const data: any[] = [];

        for (const month of months) {
            axios.get(getLeadsUrl({
                date: moment(`${month}01`, 'YYYYMMDD')
                    .format('YYYY-MM-DD'),
                formId: lead.FormId,
            }))
                .then((value: AxiosResponse<any>) => {
                    if (value?.data && Array.isArray(value?.data)) {
                        data.push(...value?.data);

                        if (month === months[months.length - 1]) {
                            const groupedMinutes = _.entries(
                                _(data)
                                    .groupBy((v: any) => {
                                        const gdate = moment(v.AppointmentDate);

                                        const range = rangeHour / rangeMinute;

                                        const min = Math.floor((gdate.minute() / rangeHour) * range) * rangeMinute;

                                        return gdate.format(`YYYYMMDDHH[${moment().minute(min)
                                            .format('mm')}]`);
                                    })
                                    .mapValues((v: any) => v.length)
                                    .value(),
                            )
                                .map((v: any) => ({
                                    date: v[0],
                                    count: v[1],
                                }))
                                .filter((v: any) => (
                                    v.count >= (rangeMinute / durationAppointment) * numberOperator))
                                .map((v: any) => v.date);

                            setClosedMinutes(groupedMinutes);

                            const groupedHours = _.entries(
                                _(groupedMinutes)
                                    .groupBy((v: any) => moment(v, 'YYYYMMDDHHmm').format('YYYYMMDDHH'))
                                    .mapValues((v: any) => v.length)
                                    .value(),
                            ).map((v: any) => ({
                                date: v[0],
                                count: v[1],
                            }))
                                .filter((v: any) => v.count >= 4)
                                .map((v: any) => v.date);

                            setClosedHours(groupedHours);


                            const closed: Moment[] = closedDays;

                            for (const dweek of week) {
                                const day = moment(today).add(dweek, 'd');

                                const businessDay = businessDays.find((f: any) => f.Day === day.day());

                                if (businessDay) {
                                    const openTime = moment(businessDay.OpenTime, 'HH:mm');
                                    const closeTime = moment(businessDay.CloseTime, 'HH:mm');

                                    const groupedDays = groupedMinutes.filter((f) => (
                                        moment(f, 'YYYYMMDDHHmm').format('YYYYMMDD') === day.format('YYYYMMDD')));

                                    const nbHalf = closeTime.diff(openTime, 'm') / 15;

                                    if (groupedDays.length >= nbHalf) {
                                        closed.push(day);
                                    }
                                }
                            }

                            setClosedDays(closed);
                        }
                    }
                })
                .catch((reason: any) => {
                    console.error(reason);
                });
        }
    };

    useEffect(() => {
        window.scroll(0, 0);

        switch (index) {
            case 0:
                if (!initDate) {
                    getLead();
                }
                break;
            case 1:
                getLeads();
                setDate(undefined);
                break;
            case 2:
                setHour(undefined);
                break;
            default:
                break;
        }
    }, [index]);

    useEffect(() => {
        if (lead) {
            getConfig();
        }
    }, [lead]);

    useEffect(() => {
        if (reset) {
            setDate(undefined);
            setHour(undefined);
            setReset(false);
            setIndex(1);
        }
    }, [reset]);


    const makeICal = () : string | undefined => {
        const y = date?.year();
        const m = !date ? undefined : date.month() + 1;
        const d = date?.date();
        const h = date?.hour();
        const min = date?.minute();

        const event = {
            start: [y, m, d, h, min],
            duration: {
                minutes: 15,
            },
            title: 'Rendez-vous avec mon conseiller clientèle',
            description: `Rendez-vous avec mon conseiller clientèle${!message ? '' : ` ${message}`}`,
            status: 'CONFIRMED',
            busyStatus: 'BUSY',
        };

        const { error, value } = ics.createEvent(event);

        if (error) {
            return undefined;
        }

        return value;
    };

    const downloadICal = () => {
        const icalcontent = makeICal();

        if (icalcontent) {
            const blob = new Blob([icalcontent], {
                type: 'text/calendar',
            });
            const link = document.createElement('a');

            link.href = window.URL.createObjectURL(blob);
            link.download = 'reminders.ics';
            setTimeout(() => {
                link.click();
            }, 2000);
        }
    };

    useEffect(() => {
        if (confirm) {
            setDate(undefined);
            setHour(undefined);
            setConfirm(false);
            setIndex(0);

            setTimeout(() => {
                setSnackbar(true);
            }, 1000);

            if (ical) {
                downloadICal();
            }

            setIcal(false);
            setInitDate(undefined);
        }
    }, [confirm]);

    return (
        <Paper square elevation={10} className={classes.paper}>
            <AppBar color="transparent" position="static">
                <Toolbar className={classes.toolbar}>
                    {
                        index > 0 &&
                            <IconButton
                                className={classes.backButton}
                                aria-label="back"
                                color="primary"
                                onClick={handleBack}
                            >
                                <ArrowBackIcon />
                            </IconButton>
                    }
                    <div className={classes.space} />
                    <Avatar alt="logo" src={logo} className={classes.logo} />
                </Toolbar>
            </AppBar>
            {
                isLoading &&
                    <CircularProgress
                        size={80}
                        thickness={4}
                        className={classes.loader}
                    />
            }
            {
                (!isLoading && id && lead) &&
                <SwipeableViews
                    disabled
                    index={index}
                    animateTransitions={!reset && !confirm}
                    className={classes.swipeableViews}
                >
                    {
                        (index === 0 && initDate && config) ?
                            <HomeTab
                                message={message}
                                initDate={initDate}
                                handleUpdate={handleUpdate}
                            />
                            : <div />
                    }
                    {
                        (index === 1 && initDate && closedWeekdays && closedDays) ?
                            <DateTab
                                dateMin={today}
                                initDate={initDate}
                                closedDays={closedDays}
                                closedWeekdays={closedWeekdays}
                                handleDateChange={handleDateChange}
                            />
                            : <div/>
                    }
                    {
                        (index === 2 && date && closedMinutes && closedHours) ?
                            <HoursTab
                                date={date}
                                hour={hour}
                                minutes={minutes}
                                rangeMinute={rangeMinute}
                                businessDays={businessDays}
                                closedMinutes={closedMinutes}
                                closedHours={closedHours}
                                handleConfirmHour={handleConfirmHour}
                                handleSelectHour={handleSelectHour}
                                handleSelectMinutes={handleSelectMinutes}
                            />
                            : <div/>
                    }
                    {
                        (index === 3 && date && hour) ?
                            <ResumeTab
                                message={message}
                                date={date}
                                minutes={minutes}
                                hour={hour}
                                ical={ical}
                                setIcal={setIcal}
                                handleReset={handleReset}
                                handleConfirm={handleConfirm}
                            />
                            : <div/>
                    }
                </SwipeableViews>
            }
            {
                ((!id && !isLoading) || (!lead && !isLoading)) &&
                <div className={classes.notfound}>
                    <Typography>{'Nous n\'avons pas pu retrouver votre rendez-vous.'}</Typography>
                    <Typography>Veuillez-nous excuser pour la gêne occasionnée.</Typography>
                </div>
            }
            <Snackbar open={snackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert
                    variant="filled"
                    onClose={handleCloseSnackbar}
                    severity="info"
                    classes={
                        {
                            filledInfo: classes.filledInfo,
                        }
                    }
                >
                    Votre rendez-vous a été modifié
                </Alert>
            </Snackbar>
        </Paper>
    );
};

export default App;
