/* eslint-disable camelcase */
import React from 'react';

import { Avatar, Button, Checkbox,
    Grid, List, ListItem, ListItemAvatar,
    ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import InsertInvitationIcon from '@material-ui/icons/InsertInvitation';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import clsx from 'clsx';
import moment, { Moment } from 'moment';

import useStyles from './theme/style';

import 'moment/locale/fr';


moment.locale('fr');

const ResumeTab = (
    {
        message,
        date,
        minutes,
        hour,
        ical,
        setIcal,
        handleReset,
        handleConfirm,
    } : {
        message: string | undefined,
        date: Moment,
        minutes: number,
        hour: number,
        ical: boolean,
        setIcal: any,
        handleReset: any,
        handleConfirm: any
    },
) => {
    const classes = useStyles();

    const sHour = moment()
        .hour(hour)
        .minute(minutes)
        .format('HH[h]mm');

    const handleICal = (event: any) => {
        event.stopPropagation();
        setIcal(!ical);
    };

    const formatFinalDate = (value: Moment): Moment => value
        .hour(hour)
        .minute(minutes)
        .second(0)
        .millisecond(0);

    return (
        <>
            <List className={classes.list}>
                <ListItem>
                    <ListItemAvatar>
                        <Avatar
                            className={clsx(classes.primary, classes.transparent)}
                        >
                            <PhoneIphoneIcon />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        classes={
                            {
                                primary: clsx(classes.fontLarge, classes.primary),
                            }
                        }
                        primary={`Votre rendez-vous${!message ? '' : ` ${message}`}, sera enregistré pour le`}
                        secondary={
                            <>
                                <Typography
                                    component="span"
                                    className={clsx(classes.fontLarge, classes.capitalize)}
                                >
                                    {`${date.format('dddd DD MMMM')}`}
                                </Typography>
                                <Typography
                                    component="span"
                                    className={classes.fontLarge}
                                >
                                    {' à '}
                                </Typography>
                                <Typography
                                    component="span"
                                    className={clsx(classes.fontLarge, classes.bold)}
                                >
                                    {`${sHour}`}
                                </Typography>
                            </>
                        }
                    />
                </ListItem>
            </List>
            <Grid container spacing={1} className={classes.buttonConfirmContainer}>
                <Grid
                    item
                    xs={12}
                    style={
                        {
                            padding: 0,
                            marginBottom: 20,
                        }
                    }
                >
                    <List>
                        <ListItem
                            button
                            onClick={handleICal}
                            classes={
                                {
                                    button: classes.listItembutton,
                                }
                            }
                        >
                            <ListItemAvatar>
                                <Avatar
                                    className={clsx(classes.primary, classes.transparent)}
                                >
                                    <InsertInvitationIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                classes={
                                    {
                                        primary: classes.listItemTextPrimaryMessage,
                                    }
                                }
                                primary={'Ajouter à votre calendrier'}
                            />
                            <ListItemSecondaryAction
                                style={
                                    {
                                        zIndex: -1,
                                    }
                                }
                            >
                                <Checkbox
                                    edge="end"
                                    onChange={handleICal}
                                    checked={ical}
                                    color="primary"
                                    // style={
                                    //     {
                                    //         marginLeft: 0,
                                    //         marginRight: 20,
                                    //     }
                                    // }
                                />
                            </ListItemSecondaryAction>
                        </ListItem>
                    </List>
                </Grid>
                <Grid item xs={6}>
                    <Button
                        className={classes.buttonConfirm}
                        variant="contained"
                        disableElevation
                        color="default"
                        onClick={handleReset}
                        classes={
                            {
                                label: classes.labelButton,
                            }
                        }
                    >
                                    Modifier
                    </Button>
                </Grid>
                <Grid item xs={6}>
                    <Button
                        className={classes.buttonConfirm}
                        variant="contained"
                        disableElevation
                        color="primary"
                        onClick={() => handleConfirm(formatFinalDate(date))}
                        classes={
                            {
                                label: classes.labelButton,
                            }
                        }
                    >
                        Confirmer
                    </Button>
                </Grid>
            </Grid>
        </>
    );
};

export default ResumeTab;
