/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';

import { Avatar, Button, Collapse, Grid,
    ListItem, ListItemAvatar, ListItemText, List } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import clsx from 'clsx';
import moment, { Moment } from 'moment';
import SwipeableViews from 'react-swipeable-views';

import { rangeHour } from '../../App';
import useStyles from './theme/style';
import 'moment/locale/fr';


moment.locale('fr');

type rangeGridType = 2 | 1 | 10 | 12 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 11;

const HourTab = (
    {
        date,
        minutes,
        hour,
        businessDays,
        closedMinutes,
        closedHours,
        rangeMinute,
        handleSelectHour,
        handleSelectMinutes,
        handleConfirmHour,
    } : {
        date: Moment,
        hour: number | undefined,
        minutes: number,
        businessDays: any,
        rangeMinute: number,
        closedMinutes: string[],
        closedHours: string[],
        handleSelectHour: any,
        handleSelectMinutes: any,
        handleConfirmHour: any
    },
) => {
    const classes = useStyles();

    const [hours, setHours] = useState<number[]>([]);

    const range = rangeHour / rangeMinute;

    const rangeGrid = Math.ceil(12 / range) as rangeGridType;

    const minutesRange = Array.from(Array(range).keys()).map((i) => i * rangeMinute);

    const getTime = () => {
        const businessDay = businessDays.find((f: any) => f.Day === date.day());

        const formattedDay = date.format('YYYYMMDD');
        const openTime = moment(`${formattedDay}${businessDay.OpenTime}`, 'YYYYMMDDHH:mm');
        const closeTime = moment(`${formattedDay}${businessDay.CloseTime}`, 'YYYYMMDDHH:mm');

        return {
            openTime,
            closeTime,
        };
    };

    const isClosedMinutes = (value: string): boolean => {
        const momentValue = moment(value, 'YYYYMMDDHHmm');
        const { openTime, closeTime } = getTime();

        return closedMinutes.find((f) => f === value) !== undefined ||
        (
            momentValue.isSameOrAfter(closeTime) || momentValue.isBefore(openTime)
        );
    };

    useEffect(() => {
        const { openTime, closeTime } = getTime();

        setHours(Array.from({
            length: (((closeTime.minute() === 0
                ? closeTime.hour() - 1
                : closeTime.hour()) - openTime.hour()) + 1),
        }, (_, i) => i + openTime.hour()));
    }, [businessDays]);

    return (
        <>
            <List className={classes.list}>
                <ListItem>
                    <ListItemAvatar>
                        <Avatar
                            className={clsx(classes.primary, classes.transparent)}
                        >
                            <AccessTimeIcon />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        classes={
                            {
                                primary: clsx(classes.fontLarge, classes.primary),
                            }
                        }
                        primary={'Quelle heure vous convient le mieux ?'}
                    />
                </ListItem>
                <ListItem>
                    <ListItemText
                        primary={
                            <>
                                <Typography
                                    className={classes.daylittleResume}
                                >
                                    {date.format('dddd').toLocaleLowerCase()}
                                </Typography>
                                <Typography
                                    className={classes.littleResume}
                                >
                                    {date.format('DD MMMM YYYY').toLocaleLowerCase()}
                                </Typography>
                            </>
                        }
                    />
                </ListItem>
            </List>
            <div className={classes.cardContent}>
                {
                    hours.map((value) => (
                        <>
                            {
                                (date.format('YYYYMMDD') !== moment().format('YYYYMMDD') || value >= moment().hour()) &&
                                    <>
                                        <SwipeableViews
                                            key={value}
                                            disabled
                                            slideClassName={
                                                clsx(classes.slide, {
                                                    [classes.halfSlide]: (value === hour),
                                                })
                                            }
                                        >
                                            <>
                                                <Button
                                                    className={classes.buttonHours}
                                                    variant="outlined"
                                                    color="primary"
                                                    onClick={() => handleSelectHour(value)}
                                                    disableElevation
                                                    disabled={
                                                        closedHours
                                                            .find((f) => f === date
                                                                .hour(value)
                                                                .format('YYYYMMDDHH')) !== undefined
                                                    }
                                                    classes={
                                                        {
                                                            label: classes.labelButton,
                                                        }
                                                    }
                                                >
                                                    {
                                                        moment()
                                                            .hour(value)
                                                            .minute(value === hour ? minutes : 0)
                                                            .format('HH:mm')
                                                    }
                                                </Button>
                                            </>
                                            <>
                                                <Button
                                                    className={classes.buttonConfirmMasked}
                                                    variant="contained"
                                                    disableElevation
                                                    onClick={handleConfirmHour}
                                                    color="primary"
                                                    classes={
                                                        {
                                                            label: classes.labelButton,
                                                        }
                                                    }
                                                >
                                                    Confirmer
                                                </Button>
                                            </>
                                        </SwipeableViews>
                                        <Collapse
                                            in={value === hour}
                                            style={
                                                {
                                                    margin: '0 10px',
                                                }
                                            }
                                        >
                                            <Grid
                                                container
                                                direction="row"
                                                justify="space-between"
                                                alignItems="center"
                                                spacing={1}
                                                className={classes.containerMinutes}
                                            >
                                                {
                                                    minutesRange.map((minute) => (
                                                        <Grid
                                                            key={minute}
                                                            item
                                                            xs={rangeGrid}
                                                            className={classes.itemMinutes}
                                                        >
                                                            <Button
                                                                variant={minutes === minute ? 'contained' : 'outlined'}
                                                                color="primary"
                                                                disableElevation
                                                                disabled={
                                                                    isClosedMinutes(date
                                                                        .hour(value)
                                                                        .minute(minute)
                                                                        .format('YYYYMMDDHHmm'))
                                                                }
                                                                onClick={() => handleSelectMinutes(minute)}
                                                                classes={
                                                                    {
                                                                        root: classes.buttonMinutes,
                                                                        label: classes.fontButtonMinutes,
                                                                    }
                                                                }
                                                            >
                                                                {
                                                                    moment()
                                                                        .minute(minute)
                                                                        .format('mm')
                                                                }
                                                            </Button>
                                                        </Grid>
                                                    ))
                                                }

                                            </Grid>
                                        </Collapse>
                                    </>
                            }
                        </>
                    ))
                }
            </div>
        </>
    );
};

export default HourTab;
