/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';

import { Avatar, Button, List, ListItem, ListItemAvatar,
    ListItemText, Grid, ListItemSecondaryAction, IconButton } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import clsx from 'clsx';
import moment, { Moment } from 'moment';
import ReactResizeDetector from 'react-resize-detector';

import useStyles from './theme/style';

const tinyDatesNb: number[] = [0, 1, 2, 3];
const tinyDatesMoreNb: number[] = [0, 1, 2, 3, 4, 5, 6];

const DateTab = ({ initDate, dateMin, closedDays, closedWeekdays, handleDateChange }:
{
    initDate: Moment,
    dateMin: Moment,
    closedDays: Moment[],
    closedWeekdays: number[],
    handleDateChange: any,
}) => {
    const classes = useStyles();
    const [tinyDates, setTinyDates] = useState<Moment[]>([]);
    const [tinyMoreDates, setTinyMoreDates] = useState<Moment[]>([]);
    const [more, setMore] = useState(false);

    const isClosed = (value: Date) => {
        let closed = false;
        const momentValue = moment(value);

        closed = closedWeekdays.find((f: number) => f === momentValue.day()) !== undefined;

        if (!closed) {
            closed = closedDays
                .find((f: Moment) => f.format('YYYYMMDD') === momentValue.format('YYYYMMDD')) !== undefined;
        }

        return closed;
    };

    const handleMore = () => {
        setMore(!more);
    };

    const isLeadDate = (date: Moment) => date.format('YYYYMMDD') === initDate.format('YYYYMMDD');

    const DateTinyTab = (
        { size, datetiny }:
        {
            size: { width: number, height: number },
            datetiny: Moment,
        },
    ) => (
        <div style={
            {
                width: size.width,
                height: size.width,
            }
        }
        >
            <Button
                variant={isLeadDate(datetiny) ? 'contained' : 'outlined'}
                color="primary"
                onClick={() => handleDateChange([datetiny.toDate()])}
                style={
                    {
                        width: size.width,
                        height: size.width,
                        margin: 'auto',
                    }
                }
            >
                <Grid container>
                    <Grid item xs={12}>
                        <Typography
                            className={classes.fontMedium}
                        >
                            {datetiny.format('dddd')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography
                            className={classes.datetinyday}
                        >
                            {datetiny.format('DD')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography
                            className={classes.fontMedium}
                        >
                            {datetiny.format('MMMM')}
                        </Typography>
                    </Grid>
                </Grid>
            </Button>
        </div>
    );

    const DateTinyTabContent = ({ tDates }: { tDates: Moment[] | undefined}) => (
        <Grid container>
            <Grid item xs={12}>
                <Grid container
                    spacing={1}
                    classes={
                        {
                            'spacing-xs-1': classes.spacingxs,
                        }
                    }
                >
                    {
                        tDates && tDates.map((tdate, i: number) => (
                            <ReactResizeDetector
                                key={tdate.day()}
                                handleWidth
                                handleHeight
                                skipOnMount
                            >
                                {
                                    (size: { width: number, height: number }) => {
                                        if (i === tDates.length - 1) {
                                            return (
                                                <>
                                                    <Grid item xs={4}/>
                                                    <Grid
                                                        item
                                                        xs={4}
                                                    >
                                                        <DateTinyTab
                                                            size={size}
                                                            datetiny={moment(tdate)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={4}/>
                                                </>
                                            );
                                        }

                                        return (
                                            <Grid
                                                item
                                                xs={4}
                                            >
                                                <DateTinyTab
                                                    size={size}
                                                    datetiny={moment(tdate)}
                                                />
                                            </Grid>
                                        );
                                    }
                                }
                            </ReactResizeDetector>
                        ))
                    }
                </Grid>
            </Grid>
        </Grid>
    );

    useEffect(() => {
        let offset = 0;
        const closed: Moment[] = [];
        const closedMore: Moment[] = [];

        const idate = (tinyDateNb: number) => moment(dateMin).add(tinyDateNb + offset, 'd');

        for (const tinyDateNb of tinyDatesNb) {
            while (isClosed(idate(tinyDateNb).toDate())) {
                offset += 1;
            }

            closed.push(idate(tinyDateNb));
        }

        offset = 0;
        for (const tinyDateNb of tinyDatesMoreNb) {
            while (isClosed(idate(tinyDateNb).toDate())) {
                offset += 1;
            }

            closedMore.push(idate(tinyDateNb));
        }

        setTinyMoreDates(closedMore);
        setTinyDates(closed);
    }, []);

    return (
        <>
            <List className={classes.list}>
                <ListItem>
                    <ListItemAvatar>
                        <Avatar
                            className={clsx(classes.primary, classes.transparent)}
                        >
                            <EventAvailableIcon />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        classes={
                            {
                                primary: clsx(classes.fontLarge, classes.primary),
                            }
                        }
                        primary={'Programmez la date qui vous convient le mieux'}
                    />
                </ListItem>
            </List>
            {
                (tinyDates?.length > 0 && tinyMoreDates?.length > 0) &&
                    <>
                        <DateTinyTabContent tDates={!more ? tinyDates : tinyMoreDates} />
                        <Grid container spacing={0}>
                            <Grid item xs={12}>
                                <List>
                                    <ListItem
                                        button
                                        onClick={handleMore}
                                        classes={
                                            {
                                                container: classes.buttonMore,
                                            }
                                        }
                                    >
                                        <ListItemText
                                            primary={`Afficher ${!more ? 'plus' : 'moins'} de dates`}
                                            classes={
                                                {
                                                    primary: classes.primary,
                                                }
                                            }
                                        />
                                        <ListItemSecondaryAction
                                            style={
                                                {
                                                    right: 0,
                                                }
                                            }
                                        >
                                            <IconButton onClick={handleMore}>
                                                {
                                                    !more ?
                                                        <ArrowDropDownIcon color="primary" />
                                                        : <ArrowDropUpIcon color="primary" />
                                                }
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                </List>
                            </Grid>
                        </Grid>
                    </>
            }
        </>
    );
};

export default DateTab;
