import { createStyles, makeStyles, Theme } from '@material-ui/core';

import { capitalize, primary, transparent, bold, fontLarge, fontMedium, fontButton } from '../../../style';


const useStyles = makeStyles(
    (theme: Theme) => createStyles({
        transparent: {
            ...transparent,
        },
        primary: {
            ...primary(theme),
        },
        capitalize: {
            ...capitalize,
        },
        bold: {
            ...bold,
        },
        center: {
            textAlign: 'center',
        },
        fontLarge: {
            ...fontLarge(theme),
        },
        fontMedium: {
            ...fontMedium(theme),
        },
        fontButton: {
            ...fontButton(theme),
        },
        list: {
            width: '100%',
            position: 'absolute',
            top: '20vh',
            '@media (max-height: 650px) and (orientation: landscape)': {
                top: '0vh',
            },
        },
        buttonConfirm: {
            position: 'absolute',
            left: 'calc(50% - 115px)',
            bottom: '20vh',
            width: 230,
            height: 60,
            [`${theme.breakpoints.down('xs')}`]: {
                left: 'calc(50% - 20vw)',
                width: '40vw',
            },
            '@media (max-height: 650px) and (orientation: landscape)': {
                bottom: '10vh',
            },
        },
    }),
);

export default useStyles;
